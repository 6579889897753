import React, { useState ,useContext,useEffect,useRef} from 'react';

import {
  CitySelect,
  //CountrySelect,
  StateSelect,
  //LanguageSelect,
} from "react-country-state-city";
import { AuthContext } from '../../context/AuthContext';
import "react-country-state-city/dist/react-country-state-city.css";
import { SearchOutlined} from '@ant-design/icons';
import { Link ,useNavigate} from "react-router-dom";
import { errorToast } from "../../components/Toaster";
import { PlusOutlined } from '@ant-design/icons';
import { CloseSVG } from "../../assets/images";
import {  Input, } from "../Input";
import { Button, Divider,  Select, Space } from 'antd';
let index = 0;

const dropDownOptions = [
  { label: "$0k", value: "0" },
  { label: "$10k", value: "10000" },
  { label: "$20k", value: "20000" },
  { label: "$30k", value: "30000" },
  { label: "$40k", value: "40000" },
  { label: "$50k", value: "50000" },
  { label: "$60k", value: "60000" },
  { label: "$70k", value: "70000" },
  { label: "$80k", value: "80000" },
  { label: "$90k", value: "90000" },
  { label: "$100k", value: "100000" },
  { label: "$200k", value: "200000" },
  { label: "$300k", value: "300000" },
  { label: "$400k", value: "400000" },
  { label: "$500k", value: "500000" },
  { label: "$600k", value: "600000" },
  { label: "$700k", value: "700000" },
  { label: "$800k", value: "800000" },
  { label: "$900k", value: "900000" },
  { label: "$1M", value: "1000000" },
  { label: "$2M", value: "2000000" },
  { label: "$3M", value: "3000000" },
];

const dropDownOptions3 = [
  { label: "$10k", value: "10000" },
  { label: "$20k", value: "20000" },
  { label: "$30k", value: "30000" },
  { label: "$40k", value: "40000" },
  { label: "$50k", value: "50000" },
  { label: "$60k", value: "60000" },
  { label: "$70k", value: "70000" },
  { label: "$80k", value: "80000" },
  { label: "$90k", value: "90000" },
  { label: "$100k", value: "100000" },
  { label: "$200k", value: "200000" },
  { label: "$300k", value: "300000" },
  { label: "$400k", value: "400000" },
  { label: "$500k", value: "500000" },
  { label: "$600k", value: "600000" },
  { label: "$700k", value: "700000" },
  { label: "$800k", value: "800000" },
  { label: "$900k", value: "900000" },
  { label: "$1M", value: "1000000" },
  { label: "$2M", value: "2000000" },
  { label: "$3M", value: "3000000" },
];
const Search = () => {
  const navigate = useNavigate();
  const { GetCounties} = useContext(AuthContext);
  const [searchBarValue9, setSearchBarValue9] = React.useState("");
  const [search, setSearch] = useState('');
  const [price, setPrice] = useState('');
  const [resCounty, setData] = useState([]);
  const [gender, setGender] = React.useState("For Sale");
  const [items, setItems] = useState(['$150k - $300k', '$600k - $700k']);
  const [name, setName] = useState('');
  const [min_price, setMin] = useState('');
  const [max_price, setMax] = useState('');
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  const handleSearch = () => {
    if (search == "") {
      errorToast('Search Field is empty')
    }else{
    const queryParams = new URLSearchParams();
    if (search) queryParams.append('search', search); // Add the search parameter
    if (min_price) queryParams.append('min_price', min_price);
    if (max_price) queryParams.append('max_price', max_price);

    // Perform search action here
    console.log({ search, min_price, max_price });

    // Navigate to the search endpoint with the query parameters
    navigate(`/search/${queryParams.toString() || 'none'}`)}
};
//   const handleSearch = () => {
    
//     const queryParams = new URLSearchParams();
//   if (min_price) queryParams.append('min_price', min_price);
//   if (max_price) queryParams.append('max_price', max_price);
//   if (state) queryParams.append('state', state);
//   if (count) queryParams.append('counties', count);
//   if (city) queryParams.append('city', city);

// //   {
// //     min_price: min_price,
// //     max_price: max_price,
// //     state: state3,
// //     counties: count,
// // city: city,
// // }
//     // Perform search action here
//      console.log({ state});
    // if (state == "") {
    //   errorToast('State Field is empty')
    // }
//     // else if (price == "") {
//     //   errorToast('Price Field is empty')
//     // }else{
//       navigate(`/search/${queryParams.toString() || 'none'}/${gender}`)
//     // }
//   };
  // fetchRequests()

  const forSaleRequests = async () => {
 navigate(`/listing`)
  }
  const dealRequests = async () => {
    navigate(`/subDeals`)
  }
  const sellerRequests = async () => {
    navigate(`/sellerFinance`)
  }
  return (
    <div style={styles.container}>
      <div style={styles.tabContainer}>
     
<button style={styles.tabButton} onClick={forSaleRequests}>
  {/* <input  type="checkbox"
        checked={gender === "For Sale"}
        onChange={() => setGender("For Sale")} />  */}
         For Sale</button>
        <button onClick={dealRequests} style={styles.tabButton}>
          {/* <input  type="checkbox"
        checked={gender === "Sub to Deal"}
        onChange={() => setGender("Sub to Deal")}/>  */}
         Sub to Deal</button>
        <button onClick={sellerRequests} style={styles.tabButton}>
          {/* <input  type="checkbox"
        checked={gender === "Seller Finance"}
        onChange={() => setGender("Seller Finance")}/> */}
          Seller Finance</button>
      </div>
      <br/>
      <div style={styles.inputContainer}>
      <Input
   shape="round"
                    name="search"
                    placeholder="city, county, state, or zip code"
                    value={search}
                    onChange={(e) => setSearch(e)}
                    suffix={
                      search?.length > 0 ? (
                        // <CloseSVG onClick={() => setState3("")} height={24} width={24} fillColor="#626262ff" />
                        <CloseSVG onClick={() => setSearch("")} height={24} width={24} fillColor="#626262ff" />
                      ) : (
                        
                     <>
              
                     </>
                      )
                    }
                    // onChange={(e) => setState3(e)}
                    // suffix={
                    //   state?.length > 0 ? (
                    //     <CloseSVG onClick={() => setState3("")} height={24} width={24} fillColor="#626262ff" />
                    //   ) : (
                        
                    //  <>
              
                    //  </>
                    //   )
                    // }
                    style={{height:45}}
                    className="w-[55%] h-[120%] md:w-full gap-[35px] !text-gray-700 font-semibold border-blue_gray-100_01 border border-solid"
                  />
        {/* <StateSelect placeHolder="State" countryid={county}  style={styles.select} value={state}
         onChange={(e) => (setState(e.id), setState2(e.name),setState3(e.state_code),fetchRequests())}/>
   
        &nbsp;
        <select 
        value={count}
         onChange={(e) => (
          setCount(e.target.value)
         )}
        style={{width:'200px', textAlign:'center', padding: '10px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',} } >
          <option value="">Counties</option>
          {resCounty.map((item) => (
           
          <option  value={item}>
            {item}
          </option>
        ))}
         


        </select>
      
         &nbsp;
        <CitySelect 
         countryid={county}
         stateid={state}
         placeHolder="City" style={styles.select} value={city} onChange={(e) => setCity(e.name)}/>
  
        &nbsp; */}
  
          &nbsp;
        <Select
  // defaultValue={[  min_price , max_price]}
      style={{
        width: 200,
        height:45,
        textAlign:'center'
      }}
      value={price}
      onChange={(text) => setPrice(text)}
      placeholder="Price"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
              textAlign:'center'
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
             <Select
  onKeyDown={(e) => e.stopPropagation()}
      style={{
        width: 80,
      }}
      value={min_price}  
      onChange={text => setMin(text)}
      options={dropDownOptions3}
    />
     <Select
   onKeyDown={(e) => e.stopPropagation()}
      style={{
        width: 80,
      }}
      value={max_price}  
      onChange={text => setMax(text)}
      options={dropDownOptions}
    />
            {/* <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button> */}
          </Space>
        </>
      )}
      options={[
        { value: min_price +" "+ max_price, label: '$'+min_price +" - "+ '$'+max_price },
      ]
      //   items.map((item) => ({
      //   label: item,
      //   value: item,
      // }))
    }
    />
      &nbsp;
        <button style={styles.searchButton} onClick={handleSearch} className="sm:px-5 font-bold" >
        <SearchOutlined />
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // width: '800px',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderColor:''
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
   
  },
  tabButton: {
    padding: '10px 20px',
    margin: '0 5px',
    border: 'none',
    backgroundColor: '#008080',
    color:'white',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  
  },
  select: {
    padding: '10px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    // width:'30%'
    
  },
  input: {
    padding: '10px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width:'30%'
  },
  searchButton: {
    padding: '10px 20px',
    backgroundColor: '#008080',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Search;


