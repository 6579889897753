export const MenuData = 
  {"DEALS": 
    [
      {
        title: 'Sales Propertes',
        path: '/listing',
        cName: 'dropdown-link'
      },
      {
        title: 'Sub to Deal',
        path: '/subDeals',
        cName: 'dropdown-link'
      },
      {
        title: ' Seller Finance',
        path: '/sellerFinance',
        cName: 'dropdown-link'
      },
     
    ],  
    "PROPERTIES": 
    [
      {
        title: ' All Propertes',
        path: '/all',
        cName: 'dropdown-link'
      },
      {
        title: 'Multi-Family',
        path: '/multiFamily',
        cName: 'dropdown-link'
      },
      {
        title: 'Residential',
        path: '/residential',
        cName: 'dropdown-link'
      },
  
    ],
     
  };