import { useNavigate  } from 'react-router-dom';
import  validator from 'validator'
import React, {createContext, useEffect, useState} from 'react';
import axios from "axios";
import  Request  from '../API/api';
import { successToast,errorToast } from "../components/Toaster";
export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({}); 
  const [isLoading, setIsLoading] = useState(false);


  const SaveOffer = async (data) => {
    try {
      const response = await Request.post('offers/', data);
      if(response){
        successToast('Sent Successfully');
        return response.data;
      }
    } catch (error) {
  
      console.log(error)
    }
  }
  const Search = async (data) => {

    setIsLoading(true);
    try {
      setTimeout(() => {
        setIsLoading(false);
      },15000)
      const response = await Request.get(`properties/search?${data}`);
      return response.data;
      
    } catch (error) {
      console.log(error)
    }
  }

  const GetCounties = async (data) => {
    try {
      const response = await Request.get(`properties/counties/${data || ""}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  const getLike = async () => {
    try {
      let user_id = localStorage.getItem('user');
      const response = await Request.get(`properties/user-favourites/${user_id}`);
      return response.data;
    } catch (error) {
      console.log(error)
      
    }
  };

  const DeleteUser = async (data) => {
    try {
      let user_id = data
      const response = await Request.delete(`users/${user_id}/`);
      if(response){
        successToast('User Deleted Successfully');
        return response.data;
      }
    } catch (error) {
     
      console.log(error)
    }
  }
  const UserLike = async (data) => {
    try {
      const response = await Request.post(`properties/user-favourites`,data);
      if(response){
        successToast('Saved Successfully');
        return response.data;
      }
    } catch (error) {
      
      console.log(error)
    }
  }
  const getUser = async () => {
    try {
      let user_id = localStorage.getItem('user');
      const response = await Request.get(`users/${user_id}`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };
  const getAllUser = async () => {
    try {
      const response = await Request.get(`users/`);
      return response.data;
    } catch (error) {
      console.log(error)
      //console.error('Failed to fetch all users:', error);
      // throw error;
    }
  };

  const updateUser = async (data) => {
    setIsLoading(true)
    try {
      let user_id = localStorage.getItem('user');
      const response = await Request.put(`users/${user_id}`, data);
      if(response){
        successToast('Account Update Successful');
        setTimeout(() => {
          setIsLoading(false);
         }, 2000)
        //  navigate('')
        window.location.reload()
        return response.data;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error)
    }
  }
  const Signup = async (data) => {
    setIsLoading(true)
    try {
      const response = await Request.post('users/signup', data);
      if(response){
        successToast('Account Created Successfully');
        setTimeout(() => {
          setIsLoading(false);
         }, 2000)
         navigate('/login')
        return response.data;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error)
    }
  }

  const getProperty = async () => {
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false);
      },10000)
      const response = await Request.get(`properties/`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };
  
  const AddProperty = async (data) => {
    try {
      // const response = await Request.post(`properties`,data);
      console('Goooal',data)
      axios({
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        method: 'post',
        url: `http://localhost:8000/api/properties`,
        data: data,
        withCredentials:true,
      })
        .then((response) => {
          if(response){
            successToast('Saved Successfully');
            return response.data;
          }
        })
     
    } catch (error) {
      
      errorToast(`${error}`)
    }
  }

  const getResidential = async () => {
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false);
      },15000)
      const response = await Request.get(`properties/residential`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };
  
  const getMultifamily = async () => {
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false);
      },15000)
      const response = await Request.get(`properties/multi-family`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };
  const getSubdeals = async () => {
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false);
      },15000)
      const response = await Request.get(`properties/sub-to-deals`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };
  const getSellerfinance = async () => {
    setIsLoading(true)
    try {
      setTimeout(() => {
        setIsLoading(false);
      },15000)
      const response = await Request.get(`properties/seller-finance`);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  };

  const login = async (email,password) => {
    
    if (email == "") {
        errorToast('Email Field is empty')
      }
      else if (password == "") {
        errorToast('Password Field is Empty')
      }
      else if(!validator.isEmail(email)){
        errorToast('Please Enter a Valide Email Address');
        setIsLoading(false);
      }
      else { 
        setIsLoading(true)
        const data ={email, password}
    try {
        const response = await Request.post('users/login', data);
      if(response){

        console.log('Good',response)
        let userInfo = response.data.user;
        let sessionId = response.data.session_id; // Retrieve the session ID from the response

        setTimeout(() => {
       
          successToast("Login Successful");
          localStorage.setItem('userdetail', userInfo);
            setIsLoading(false);
            document.cookie = `session_id=${sessionId}; path=/; max-age=${60 *  60 *  24 *  7}`
            document.cookie = `id=${userInfo.id}; path=/; max-age=${60 *  60 *  24 *  7}`
            localStorage.setItem('userdetail', userInfo);
            localStorage.setItem('user', userInfo.id);
          
            navigate('/')
        }, 3000);
      }
    } catch (error) {
        setIsLoading(false);
        console.log(error)
    }}
  };

  const Adminlogin = async (email,password) => {
    
    if (email == "") {
        errorToast('Email Field is empty')
      }
      else if (password == "") {
        errorToast('Password Field is Empty')
      }
      else if(!validator.isEmail(email)){
        errorToast('Please Enter a Valide Email Address');
        setIsLoading(false);
      }
      else { 
        setIsLoading(true)
        const data ={email, password}
    try {
        const response = await Request.post('admin/login/', data);
      if(response){

        console.log('Good',response)
        let userInfo = response.data.admin;
        let sessionId = response.data.session_id; // Retrieve the session ID from the response

        setTimeout(() => {
       
          successToast("Login Successful");
          localStorage.setItem('userdetail', userInfo);
            setIsLoading(false);
            document.cookie = `session_id=${sessionId}; path=/; max-age=${60 *  60 *  24 *  7}`
            document.cookie = `id=${userInfo.id}; path=/; max-age=${60 *  60 *  24 *  7}`
            localStorage.setItem('userdetail', userInfo);
            localStorage.setItem('user', userInfo.id);
            localStorage.setItem('adminID', userInfo.id);
          
            navigate('/admin')
        }, 3000);
      }
    } catch (error) {
        setIsLoading(false);
        console.log(error)
    }}
  };
  const logout = async () => {
    try {
        const response = await Request.get('user_logout/');
      if(response){
        console.log(response.data);
        localStorage.removeItem('userdetail');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('user');
        setUserInfo({});
        navigate('/')
      }
    } catch (error) {
       
        setIsLoading(false);
        console.log(error)
    }
  };


  const isLoggedIn = async () => {
    try {
      //setSplashLoading(true);

      let userInfo = JSON.stringify(localStorage.getItem('userdetail'));
      userInfo = JSON.parse(userInfo);

      if (userInfo) {
        setUserInfo(userInfo);
      }else if(!userInfo){
        // navigate('/login')
      }
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);


  const useAuth = () => {
    //getting token from local storage
    const user = localStorage.getItem('userdetail')
    //checking whether token is preset or not
    if (user) {
        return true;
    } else {
        return false
    }
};
const adminAuth = () => {
  //getting token from local storage
  const user = localStorage.getItem('adminID')
  //checking whether token is preset or not
  if (user) {
      return true;
  } else {
      return false
  }
};
  return (
    <AuthContext.Provider
      value={{
        getProperty,
        login,
        getAllUser,
        logout,
        isLoading,
        Signup,
        updateUser,
        getUser,
        getResidential,
        getMultifamily,
        getSubdeals,
        getSellerfinance,
        UserLike,
        DeleteUser,
        getLike,
        AddProperty,
        Adminlogin,
        useAuth,
        adminAuth,
        Search,
        SaveOffer,
        GetCounties
      }}>
      {children}
    </AuthContext.Provider>
  );
};


