import React,{ useState, useContext,useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from './AuthContext';

function PrivateRoutes() {
    const { adminAuth } = useContext(AuthContext);
    const token = adminAuth()
    return token ? <Outlet /> : <Navigate to='/offadmin' />
}

export default PrivateRoutes