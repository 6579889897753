import React from "react";
// import Routes from "./Routes";
// import { BrowserRouter as Router } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {AuthProvider} from './context/AuthContext';
import PrivateRoutes from "./context/protection"
import PrivateAdmin from "./context/protect"
import NotFound from "pages/NotFound";
import LandingPage from "pages/LandingPage";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactPage";
import Listing from "pages/Listings";
import UserDashboard from "pages/UserDashboard";
import ListingMapView from "pages/ListingMapView";
import PropertyDetails from "pages/PropertyDetails";
import Signup from "pages/Signup";
import Login from "pages/Login";
import AdminLogin from "pages/AdminLogin";
import Submit from "pages/Submit";
import Profile from "pages/Dashboard";
import Offer from "pages/Dashboard/Offer";
import Saved from "pages/Dashboard/Saved";
import MultiFamily from "pages/MultiFamily";
import Residential from "pages/Residential";
import SellerFinanc from "pages/SellerFinance";
import SubDeals from "pages/SubDeals";
import Search from "pages/Search";

function App() {
  return (

      <BrowserRouter>
       <AuthProvider>
       <ToastContainer/>
      <Routes>
     
      <Route path="/" element={<LandingPage />}/>
          {/* <Route index element={<LandingPage />} /> */}
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />


          <Route path="search/:queryParams" element={<Search />} />
          <Route path="multiFamily" element={<MultiFamily />} />
          <Route path="residential" element={<Residential />} />
          <Route path="sellerFinance" element={<SellerFinanc />} />
          <Route path="subDeals" element={<SubDeals />} />
       

          <Route element={<PrivateRoutes />}>
          <Route path="offer" element={<Offer />} />
          <Route path="profile" element={<Profile />} />
          <Route path="saved" element={<Saved />} />
          <Route path="propertydetails/:id" element={<PropertyDetails />} />
          </Route>
          
          <Route element={<PrivateAdmin />}>
          <Route path="admin" element={<UserDashboard />} />
          </Route>
         
          <Route path="offadmin" element={<AdminLogin />} />
          <Route path="all" element={<ListingMapView />} />
          
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="submit" element={<Submit />} />
         
          <Route path="listing" element={<Listing />} />
        {/* </Route> */}
     
        
      </Routes>
      </AuthProvider>
    </BrowserRouter>
  
  );
}

export default App;
