import React,{ useState, useContext,useEffect } from 'react';
import { Modal } from 'antd';


import { Link,  useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { CloseSVG } from "../../assets/images";
import { Button, Img, GoogleMap, SelectBox, Input, Heading,Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header2";
import LandingPageCard from "../../components/LandingPageCard";
import BgHome from "../../assets/images/blueribbon.png";
import BgSky from "../../assets/images/Mask.png";
import { AuthContext } from '../../context/AuthContext';
import Img12 from '../../assets/images/pexels-harrisonhaines-3082638.jpg'
import Img1 from '../../assets/images/icons8-power-50.png'
import Img2 from '../../assets/images/icons8-heart-50.png'
import Img3 from '../../assets/images/icons8-share-64.png'
import Img4 from '../../assets/images/img_icon_gray_700.svg'
import Img5 from '../../assets/images/img_icon_gray_700_20x20.svg'
import Img6 from '../../assets/images/img_icon_gray_700.svg'
import Img7 from '../../assets/images/img_icon_gray_700_20x20.svg'
import LoadingPage from "../../components/Loader";
import NoImg from "../../assets/images/img_image_1.png";
import Pagination  from '../../context/pagination';
const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];



export default function ListingMapViewPage() {
  let { queryParams} = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(21);
    let ID = localStorage.getItem("user") || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [payments, setPayment] = useState([]);
  const [id, setID] = useState("");
  
  const { isLoading,UserLike,Search,getSubdeals,getSellerfinance,getProperty} = useContext(AuthContext);
  useEffect(() => {
    const fetchRequests = async () => {
     let data = (queryParams)
      console.log("dt",data)
      if(queryParams){
        const res = await Search(data) //Search(data);
        console.log(res)
        if(res){
          setPayment(res);
          
         }else{
          console.log('No data')
         }
      }
      
   
    };
    fetchRequests();
  }, [])

  const handleLike = (id) => {
    let property_id = id;
    let user_id = localStorage.getItem('user');
    let data = ({property_id:property_id,user_id:user_id})
    UserLike(data)    
  }
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = payments.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(payments.length / recordsPerPage)
 
  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <div className="flex flex-col items-center justify-start w-full gap-[100px] overflow-auto bg-gray-50_01">
      <Header className="flex justify-center items-center w-full md:h-auto  " />
        
     


          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-col items-center justify-start w-full gap-[60px] md:px-5 max-w-[1200px]">
              {/* <GoogleMap showMarker={false} className="h-[428px] w-full" /> */}
              <div className="flex flex-col items-center justify-start w-full">
              {
        payments.length >=1 ?
        <>
                        <div className="justify-center w-full gap-6 grid-cols-3 md:grid-cols-2 md:gap-5 sm:grid-cols-1 grid">
        {
        // payments.slice(currentPage * postsPerPage, currentPage * postsPerPage + postsPerPage)
        currentRecords.map((datas) => {

                               return (
             <div key={datas.id}>
                                 <Link to={`/propertydetails/${datas.id}`} >
                                 <Img
                                   src={datas.Images[0] || NoImg}
                                   alt="image_one"
                                   style={{width:383, height:300}}
                                   className="w-full md:h-auto sm:w-full rounded-tr-[10px] rounded-tl-[10px] object-cover"
                                 />
                                 </Link>
                                 <div style={{width:383, height:250}} className="flex flex-row justify-center w-full p-[19px] rounded-bl-[10px] rounded-br-[10px] border-red-100_01 border border-solid bg-gray-50_01">
                                   <div className="flex flex-col items-center justify-start w-full gap-[25px] my-2.5 md:px-5 max-w-[344px]">
                                     <div className="flex flex-row justify-start items-center w-full gap-3 ">
                                       <div className="absolute">
                                       {/* <Img src="images/img_icon_map_2.svg" alt="image_two" className="h-[24px] w-[24px] " /> */}
                                       <Button
                                                 color="white_A700"
                                                 size="lg"
                                                 shape="round"
                                              
                                                 className="gap-1.5 -top-[120px] left-[5px] m-auto text-gray-900 font-bold min-w-[122px] absolute"
                                               >
                                                  {/* {price} */}
                                                  {datas.Price}
                                               </Button>
                                               <Button
                                                 color="white_A700"
                                                 size="lg"
                                                 shape="round"
                                                 leftIcon={<Img src={Img1}  alt="icon - image" style={{width:18}}/>}
                                                 className="gap-1.5 -top-[220px] left-[5px] m-auto text-gray-900 font-bold min-w-[122px] absolute"
                                               style={{background:'#008080',color:'#fff',fontSize:'12px'}}
                                               >
                                                 FEATURED
                                               </Button>
                                       </div>
                                       <Link to={`/propertydetails/${datas.id}`} >
                                       <Heading style={{height:50}} as="h1">
                                         {/* {p286162ndave} */}
                                         {datas.Title}
                                       </Heading>
                                   </Link>
                                     </div>
                                     <div className="flex flex-col items-center justify-start w-full gap-[19px]">
                                       <div className="flex flex-row justify-between w-full">
                                         <div className="flex flex-row justify-start items-center gap-3">
                                          
                                    {ID.length >= 1 ? (
                                      <Heading
                                        as="h2"
                                        className="!text-gray-700"
                                      >
                                        {datas.Address}
                                      </Heading>
                                    ) : (
                                      <></>
                                    )}
                                         </div>
                                         <div className="flex flex-row justify-start items-center gap-3">
                                           <Img src={Img3} alt="1_bath_one" className="h-[20px] w-[20px]" />
                                           <Img onClick={() => handleLike(datas.id)} src={Img2} alt="1_bath_one" className="h-[20px] w-[20px]" />
                                         </div>
                                       </div>
                                     
                                     </div>
                           
                                     <div className="flex flex-row justify-between items-center w-full pr-[47px] md:pr-5">
                                     <div className="flex flex-row md:flex-col justify-start w-full gap-2.5 md:gap-5">
                                             <Button
                                               color="blue_gray_100"
                                               size="sm"
                                               variant="outline"
                                               shape="round"
                                               leftIcon={<Img src={Img4} alt="icon / 16px / close" />}
                                               className="gap-2 font-semibold min-w-[90px] w-[12px]"
                                               style={{width:5, fontSize:10}}
                                             >
                                               {datas.Bedrooms }-Bedrooms 
                                             </Button>
                                             <Button
                                               color="blue_gray_100"
                                               size="sm"
                                               variant="outline"
                                               shape="round"
                                               leftIcon={<Img src={Img5} alt="icon / 16px / close" />}
                                               className="gap-2 font-semibold min-w-[100px] w-[12px]"
                                               style={{width:5, fontSize:10}}
                                             >
                                               {datas.Bathrooms }-Bathrooms
                                             </Button>
                                           
                                             <Button
                                               color="blue_gray_100"
                                               size="sm"
                                               variant="outline"
                                               shape="round"
                                               leftIcon={<Img src={Img6}  alt="icon / 16px / close" />}
                                               className="gap-2 font-semibold min-w-[70px] w-[12px]"
                                               style={{width:5, fontSize:10}}
                                             >
                                               {datas.Bedrooms }-Sqr. 
                                             </Button>
                                             <Button
                                               color="blue_gray_100"
                                               size="sm"
                                               variant="outline"
                                               shape="round"
                                               leftIcon={<Img src={Img7}alt="icon / 16px / close" />}
                                               className="gap-2 font-semibold min-w-[65px] w-[12px]"
                                               style={{width:5, fontSize:10}}
                                             >
                                               {datas.Bathrooms }-Suite
                                             </Button>
                                           </div>
                                          
                                     
                                     </div>
                                     
                                     
                                   </div>
                                 </div>
                               
                                 </div>
                               )
                             })}
                                </div>
        </>
            :
            <>

{isLoading ? (
                                        <>
                                            <div className="my-5">
                                                <LoadingPage />
                                            </div>
                                        </>
                                    ) : (
                                      <div style={{textAlign:'center'}}>
                                      <h3 style={{color:'red',margin:'auto'}}> No Property Found</h3>
                                     </div> 
                                    )}
         
            
          
            
               </>
    }
              </div>
                       <div className="flex flex-row sm:flex-col justify-between w-full sm:gap-10">
                <div className="flex flex-row justify-start gap-[5px]">
                  <Button color="gray_700" variant="outline" shape="round" className="font-semibold min-w-[48px]">
                    
                    <span style={{color:'#0008'}}>{currentPage}</span>/<span>{nPages}</span>
                  </Button>
                 
                </div>
                {/* <div className="flex flex-row justify-left gap-[5px]"> */}
                <Pagination

nPages={nPages}
currentPage={currentPage}
setCurrentPage={setCurrentPage}

  />
             
              </div>
            </div>
          </div>

          
          <div className="flex flex-col items-center justify-center w-full px-14 py-[70px] md:p-5 ">
          <div className="flex flex-col items-center justify-start w-full gap-[150px] max-w-[1200px]">
           
           

<div className="flex flex-col items-start justify-center w-[49%] md:h-auto gap-[49px] p-[50px] md:p-5  rounded-[20px]" style={{  background:  `url(${BgSky})` , backgroundSize: 'cover',
 backgroundRepeat: 'no-repeat', height: '70vh',
  width: '100%'
 }}>
              <div className="flex flex-col items-center justify-start mt-[23px] gap-[15px]">
                <Heading size="4xl" as="h6" className="tracking-[-0.72px]"style={{color:'black', marginRight:'90px'}}>
                Let us help you find <br/> your dream home
                </Heading>
                <Text as="p" className="!text-gray-900" style={{color:'#008080', marginRight:'10px'}}>
                We provide a complete service for the sale of homes <br/>and real estate
                </Text>
              </div>
              <Button shape="round" style={{backgroundColor:'#008080'}} className="mb-[23px] sm:px-5 font-semibold min-w-[138px] sm:min-w-full">
                CONTACT US
              </Button>
            </div>

   
          </div>
        </div>

        <Footer className="flex justify-center items-center w-full pl-[74px] pr-14 gap-[115px] py-[74px] md:p-5 bg-white-A700" />
      </div>

      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    
    </>
  );
}
