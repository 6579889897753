import React from "react";
import { Heading, Img, Text } from "./..";

import { Link } from "react-router-dom";
import { SearchOutlined, FacebookOutlined,XOutlined,InstagramOutlined,TikTokOutlined} from '@ant-design/icons';

export default function Footer({ ...props }) {
  return (
    
       <footer {...props} className="flex justify-center items-center w-full px-14 py-[74px] md:p-5 bg-white-A700">
          <div className="flex flex-col items-center justify-center w-full mt-[5px] gap-[115px] mx-auto max-w-[1200px]">
            <div className="flex flex-row md:flex-col justify-start items-center w-full gap-px md:gap-5">
              <div className="flex flex-col items-center justify-start w-[29%] md:w-full gap-[43px]">
                <div className="flex flex-row justify-start items-start w-full gap-[11px]">
                  {/* <Img src="images/img_real_estate_1.svg" alt="realestateone" className="h-[40px] w-[40px]" /> */}
                  <Text size="md" as="p" style={{color:'#008080'}}className="mt-[5px] !text-blue-A700 !font-markoone">
                    BEST OFF MARKET DEAL
                  </Text>
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-7">
                  <Heading as="h6" className="!leading-[160%]">
                    <>
                      59 Bervely Hill Ave, Brooklyn Town,
                      <br />
                      New York, NY 5630, CA, US
                    </>
                  </Heading>
                  <div className="flex flex-col items-start justify-center gap-2">
                    <Heading as="h6" className="mt-px">
                      +1(614) 647-7088
                    </Heading>
                    <Heading as="h6">contact@bestoffmarketdeal.com</Heading>
                  </div>
               
                  <Heading as="h6">© {new Date().getFullYear()}. All rights reserved.</Heading>
                </div>
              </div>
              <div className="flex flex-row md:flex-col justify-between items-center w-[72%] md:w-full md:gap-10">
                <div className="flex flex-col items-start justify-start w-[19%] md:w-full gap-[15px]">
                  <Heading size="md" as="h6">
                    Quick Links
                  </Heading>
                  <div className="flex flex-col items-start justify-start w-full gap-[15px]">
                    <Link to={'/'}><Heading as="h6">Home</Heading></Link>
                    <Link to={'/aboutus'}><Heading as="h6">About</Heading></Link>
                    <Link to={'/contactus'}><Heading as="h6">Contact</Heading></Link>
                    <Link to={'/listing'}><Heading as="h6">Deal</Heading></Link>
                  
                  </div>
                </div>

               
                {/* <div className="flex flex-col items-start justify-start w-[19%] md:w-full gap-[15px]">
                  <Heading size="md" as="h6">
                    Information
                  </Heading>
                  <div className="flex flex-col items-start justify-start w-full pt-[3px] gap-[15px]">
                  <Link to={'/all'}><Heading as="h6">Listings </Heading></Link>
                  <Link to={'/residential'}><Heading as="h6">Residential</Heading></Link>
                  <Link to={'/multiFamily'}><Heading as="h6">Multi-Family</Heading></Link>
                  <Link to={'/sellerFinance'}><Heading as="h6">Seller Finance</Heading></Link>
                   
                  </div>
                </div> */}
                <div className="flex flex-col items-start justify-start w-[19%] md:w-full gap-[15px]">
                  <Heading size="md" as="h6">
                    Our Company
                  </Heading>
                  <div className="flex flex-col items-start justify-center w-full gap-[15px]">
                    <Heading as="h6" className="mt-0.5">
                      Blog
                    </Heading>
                    <Heading as="h6">FAQ</Heading>
                    <a href="#">
                      <Heading as="h6">Privacy Policy</Heading>
                    </a>
                    <Heading as="h6">License</Heading>
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start w-[19%] md:w-full gap-[15px]">
                  <Heading size="md" as="h6">
                    Social Media 
                  </Heading>
                  <div className="flex flex-col items-start justify-start w-full pt-[3px] gap-[15px]">
                  <Link to={'#'}><FacebookOutlined style={{ fontSize: '26px', color: '#08c' }}/></Link>
                  <Link to={'#'}><XOutlined style={{ fontSize: '26px', color: '#08c' }}/></Link>
                  <Link to={'#'}><InstagramOutlined style={{ fontSize: '26px', color: '#08c' }}/></Link>
                  <Link to={'#'}><TikTokOutlined style={{ fontSize: '26px', color: '#08c' }}/></Link>
                   
                  </div>
                </div>
          
              </div>
            </div>
           
          </div>
        </footer>
  );
}
