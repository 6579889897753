import React,{ useState, useContext,useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from './AuthContext';

function PrivateRoutes() {
    const { useAuth } = useContext(AuthContext);
    const token = useAuth()
    return token ? <Outlet /> : <Navigate to='/login' />
}

export default PrivateRoutes