import React,{ useState, useContext,useEffect } from 'react';
import Paper from '@mui/material/Paper';

import { Heading, Img, Text,  } from "../../components";
import Grid from '@mui/material/Grid';
import { AuthContext } from '../../context/AuthContext';
import { Card,Col, Row, } from 'antd';
import { UsergroupAddOutlined ,BankOutlined} from '@ant-design/icons'
// import ReactApexChart from 'apexcharts';
// import IncomeAreaChart from './Pie';
export default function StickyHeadTable() {
  const [properties, setProperty] = useState([]);
  const [users, setUser] = useState([]);
  const [single, setSingle] = useState('');
  const [town, setTown] = useState('');
  const [condon, setCondon] = useState('');
  const [multi, setMulti] = useState("");
  const { getProperty ,getAllUser} = useContext(AuthContext);
  
  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getProperty();
      setProperty(data.length);
      console.log("goal",data)
      const Pie1 = data.filter(i => i.Type === "Single-Family" );
      setSingle(Pie1.length)
      console.log("goal",Pie1)
      const Pie2 = data.filter(i => i.Type === "Townhouse" );
      setTown(Pie2.length)
      const Pie3 = data.filter(i => i.Type === "Multi-Family" );
      setCondon(Pie3.length)
      const Pie4 = data.filter(i => i.Type === "Condo" );
      setMulti(Pie4.length)
    };
    fetchRequests();
  }, [])

  

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getAllUser();
      setUser(data.length);
      console.log("goal",data)
    };
    fetchRequests();
  }, [])
  console.log("---=",single, town,condon,multi)
  const States = {

    series: [single, town,condon,multi],
    //[single, town,condon,multi],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Single-Family', 'Townhouse',"Condo",'Multi-Family'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

  
  return (
    <>
  
    <Grid container spacing={2}>
    <Grid item xs={8}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'black'}}>
                      <>
                      DASHBOARD
                      </>
                    </Heading>
                
                  </div>
    </Grid>
    <Grid item xs={4} className=" items-left ">
    {/* <Button onClick={showModal}  style={{width:'170px',height:'43px', borderRadius:'10px', background:'#008080',color:'white'}} >+  Add Property</Button>
     */}
    </Grid>
   
  </Grid>
  <br/>
  <Grid container spacing={2}>
    <Grid item xs={4}>
    <Card  bordered={false} style={{ width: 300 }}>
   <Grid container spacing={2}>
    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'#008080'}}>
                      <>
                     {users}
                      </>
                    </Heading>
                    <Text  as="p" style={{textAlign:'left', color:'black'}}>
                    Total Users
                    </Text>
                  </div>
    </Grid>

    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="4xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'black', marginTop:'15px'}}>
                      <>
                      <UsergroupAddOutlined/>
                      </>
                    </Heading>
                   
                  </div>
     
    </Grid>
    </Grid>

  </Card>
    </Grid>
    <Grid item xs={4}>
    <Card  bordered={false} style={{ width: 300 }}>
    <Grid container spacing={2}>
    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'#008080'}}>
                      <>
                     {properties}
                      </>
                    </Heading>
                    <Text  as="p" style={{textAlign:'left', color:'black'}}>
                    Total  Houses
                    </Text>
                  </div>
    </Grid>

    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="4xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'#008080', marginTop:'15px'}}>
                      <>
                      <BankOutlined/>
                      </>
                    </Heading>
                   
                  </div>
     
    </Grid>
    </Grid>
  </Card>
    </Grid>
    <Grid item xs={4}>
    <Card  bordered={false} style={{ width: 300 }}>
    <Grid container spacing={2}>
    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'#008080'}}>
                      <>
                     {multi}
                      </>
                    </Heading>
                    <Text  as="p" style={{textAlign:'left', color:'black', fontSize:'14px'}}>
                    Total Multi-Family 
                    </Text>
                  </div>
    </Grid>

    <Grid item xs={6}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="4xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'black', marginTop:'15px'}}>
                      <>
                      <BankOutlined/>
                      </>
                    </Heading>
                   
                  </div>
     
    </Grid>
    </Grid>
  </Card>
    </Grid>
    </Grid>


  
<br/>
<Grid container spacing={2}>
    <Grid item xs={12}>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                   <br/>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'black',paddingLeft:'10px'}}>
                      <>
                     USER Monthly Offer Report
                      </>
                    </Heading>
                  
                  </div>
{/* <IncomeAreaChart/> */}
    {/* <div id="chart">
   
        <ReactApexChart options={State.options} series={State.series} type="bar" width={600} height={330} />
      
      
      </div> */}

</Paper>
    </Grid>
    {/* <Grid item xs={5}>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <br/> <br/> <br/>
    <div id="chartx">
    <>
       <ReactApexChart options={States.options} series={States.series} type="pie" width={400} height={400} />
       </>
      </div>

      <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                   <br/>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'center', color:'black',}}>
                      <>
                     Property Report
                      </>
                    </Heading>
                    <br/>
                  </div>
</Paper>
    </Grid> */}
    </Grid>

  
    </>
  );
}