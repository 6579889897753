import React, { useState } from 'react';

import {
    BankOutlined,

  AppstoreFilled ,
  UserOutlined,
} from '@ant-design/icons';

import deal from "../../assets/images/BESTPROPERTIES.png";
import { Layout, Menu, theme } from 'antd';
import Table from './Board'
import User from './User'
import Property from './Table'

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}





const items = [
  getItem('Dashboard', '1', <AppstoreFilled onClick={console.log('Goooo')}/>),
  getItem('Properties', '2', <BankOutlined />),
  getItem('Users', '3', <UserOutlined />),
];
const App = () => {
  const [loader, setLoader] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  console.log("Gooal",loader)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <>
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider theme='light' collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} >
        <br/>
        <div className="demo-logo-vertical" >
        <img src={deal} alt="Best Off Market Deal" className="h-[60px] w-[80px]" style={{margin:'auto'}}/></div>
        <br/>
        <Menu onClick={(e) => setLoader(e.key)} theme='light' defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <br/><br/>
        
       
        <Content
          style={{
            margin: '0 16px',
          }}
        >
{
        loader == 1 ?
         <Table/>
        :
            loader == 2 ?
            <Property/>
            :
            <User/> 
    }
          
        </Content>
       
      </Layout>
    </Layout>


</>
  );
};
export default App;