import React, { useState,useEffect ,useContext} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CloseSVG } from "../../assets/images";
import deal from "../../assets/images/deal.png";
import { Button, Input, Img, Heading, Text } from "./..";
import { Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space,Typography } from 'antd';
import { MenuData } from './menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import  Request  from '../../API/api';

const logout = async () => {
  // try {
  //     const response = await Request.get('user_logout');
  //   if(response){
      // console.log(response.data);
      localStorage.removeItem('userdetail');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('user');
      // setUserInfo({});
      window.location.reload()
  //   }
  // } catch (error) {
  //   console.log(`${error}`);
   
      
  // }
};

const items = [
  {
    key: '1',
    label: (
      <Link to={'/profile'} >
        User Profile
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link to={'/offer'}>
        Offer Sent
      </Link>
    ),
   

  },
  {
    key: '3',
    label: (
      <Link to={'/saved'}>
       Saved Properties
      </Link>
    ),
  
  },
  {
    key: '4',
    danger: true,
    label: (
      <Typography onClick={logout}>
       Logo Out
      </Typography>
    ),
  },
];

export default function Header({ ...props }) {
  const [searchBarValue1, setSearchBarValue1] = React.useState("");

  const { getUser,logout} = React.useContext(AuthContext);
  const [first_name, setFirstname] = React.useState("");

  


  useEffect(() => {
    getUser()
  .then((data) => {
   if(data){
    console.log('Dat',data)
    setFirstname(data.first_name) 
   }else{
    console.log('No data')
   }
  })
  .catch((error) => console.log('Error fetching all services:', error))
}, []);
  // const [open, setOpen] = React.useState(false);
  const [flyer, setFlyer] = React.useState(false);
  const [flyerTwo, setFlyerTwo] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  

  return (
  
    <header  {...props} >

<div className="flex flex-row md:flex-col justify-between items-center w-full mx-auto md:gap-10 md:px-5 max-w-[1200px]">
        <div className="flex flex-row justify-start items-start gap-[11px]">
          <Link to={'/'}>
          <Img src={deal} alt="Best Off Market Deal" className="h-[60px] w-[80px]" />
          </Link>
         
        </div>
        <div className="flex flex-row sm:flex-col justify-between items-center w-[59%] md:w-full sm:gap-10">
          <div className="flex flex-row w-[94%] sm:w-full gap-10" >
            <div className="flex flex-row justify-start items-start w-[18%] gap-1.5" >
              <Heading as="h6" style={{color:'#fff'}}><Link to={'/'}>HOME</Link></Heading>
              
            </div>
            <div className="flex flex-row justify-start  w-[22%] gap-1.5">
              <Heading as="h6" style={{color:'#fff'}}><Link to={'/aboutus'}>ABOUT US</Link></Heading>
              
            </div>
{/* <div className="flex flex-row justify-start items-start w-[22%] gap-1.5"> */}
            {Object.keys(MenuData).map((item, index) => (
      
             <div style={{display:'inline'}} key={index} className="flex flex-row justify-start items-start w-[30%]  gap-1.5" >
          
            <Heading as="h6" onClick={(e) => handleClick(index, e)} style={{color:'#fff'}}> {item} <DownOutlined style={{color:'white'}}/> </Heading>
   
            <Menu
              anchorEl={
                // Check to see if the anchor is set.
                anchorEl && anchorEl[index]
              }
              keepMounted
              open={
                // Likewise, check here to see if the anchor is set.
                Boolean(anchorEl && anchorEl[index])
              }
              onClose={handleClose}
              getContentAnchorEl={null}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {MenuData[item].map((menuitems, menuindex) => (
                <MenuItem
                  key={menuindex}
                  selected={menuitems === item}
                  onClick={handleClose}
                >
                  <Link to={menuitems.path}>{menuitems.title}</Link>
                  
                </MenuItem>
              ))}
            </Menu>
           
          </div> 
        ))}
         
       
         
            <div className="flex flex-row justify-start items-start w-[25%] gap-1.5">
              <Heading as="h6" style={{color:'#fff'}}><Link to={'/contactus'}>CONTACT US</Link></Heading>
              {/* <Img src="images/img_arrow_down.svg" alt="arrowdown_one" className="h-[16px] w-[16px]" /> */}
            </div>
          </div>
        
          
        </div>
       
        <div className="flex flex-row justify-start items-center w-[19%] md:w-full gap-2.5">

        {localStorage.getItem('userdetail') ? (
                                        <>
                  <Dropdown
                  menu={{
                    items,
                  }}
                >

                  <a href="" onClick={(e) => e.preventDefault()}>
                    <Space>

                      <Img src="images/user.png" alt="arrowdown_one" className="h-[40px] w-[40px]" />
                      <a onClick={() => (setFlyerTwo(!flyerTwo), setFlyer(false))} alt="arrowdown_one" className="h-[25px] w-[90px]" > <Heading as="h6" style={{ color: '#fff' }}>Hi {first_name}</Heading>
                      </a>
                      <svg
                        className={
                          flyerTwo === true
                            ? "transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200"
                            : "ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>

                    </Space>
                  </a>

                </Dropdown> 
                                        </>
                                    ) : (
                                   <>
                                   <Link to={'/signup'}  className="sm:px-5 font-semibold min-w-[94px]" style={{ color:'#ffff'}}>
            SIGN UP
          </Link>
       
          <Button size="lg" shape="round" className="sm:px-5 font-semibold min-w-[94px]" style={{ backgroundColor:'#008080',color:'#ffff'}}>
           <Link to={'/login'}>LOG IN</Link>
          </Button>
             
                                   </>
                                    )}


                                    
  
          
        </div>
    
      </div>
    </header>
    
  );
}
