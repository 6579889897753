import React,{ useState, useContext,useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import { Heading, Img, Text,  } from "../../components";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { errorToast,successToast } from "../../components/Toaster";
import { AuthContext } from '../../context/AuthContext';
import { Button, Upload,Form } from 'antd';
import NoImg from "../../assets/images/img_image_1.png";
import {Modal, Col, Row, Typography,Select} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons'
import axios from "axios";
import {
  EditOutlined  ,
  DeleteOutlined
} from '@ant-design/icons';
const columns = [
  { id: 'Image', label: 'Image', minWidth: 170 },
  { id: 'Title', label: 'Title', minWidth: 200 },
  {
    id: 'Address',
    label: 'Location',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Price',
    label: 'Price',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Type',
    label: 'Property Type',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Bedrooms',
    label: 'No. of Bedrooms',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Bathrooms',
    label: 'No. of Bathrooms',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

function createData(Image, Title, Location, Price,Type,Deal,Status,Action) {
  // const density = population / size;
  return { Image, Title, Location, Price ,Type,Deal,Status,Action};
}

const rows = [
  createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
 
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
 
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
   createData(<img src='https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg?auto=compress&cs=tinysrgb&w=600' width={100} height={100} style={{borderRadius:'5px'}}/>,
   '5 Bedroom Apartment ', '2118 67th Ave S, Saint Petersburg, FL','$100,000','Multi-Family', 'Sale',   <Chip label="Available" color="success" />,<><EditOutlined /> <DeleteOutlined /></>),
 
];

const props = {
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      console.log(file, fileList);
    }
  },
  defaultFileList: [
    {
      uid: '1',
      name: 'xxx.png',
      status: 'uploading',
      url: 'http://www.baidu.com/xxx.png',
      percent: 33,
    },
    {
      uid: '2',
      name: 'yyy.png',
      status: 'done',
      url: 'http://www.baidu.com/yyy.png',
    },
    {
      uid: '3',
      name: 'zzz.png',
      status: 'error',
      response: 'Server Error 500',
      // custom error message to show
      url: 'http://www.baidu.com/zzz.png',
    },
  ],
};

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [properties, setData] = useState([]);

  
  const { getProperty,AddProperty } = useContext(AuthContext);

  const [title, setTitle] = useState('');
  const [deal_type, setDeal] = useState('');
  const [property_type, setType] = useState('');
  const [price, setPrice] = useState();
  const [address, setAddress] = useState('');
  const [bedrooms, setBedrooms] = useState();
  const [bathrooms, setBathrooms] = useState();
  const [kitchen, setKitchen] = useState();
  const [area, setArea] = useState();
  const [lot_size, setSize] = useState();
  const [year_built, setBuilt] = useState();
  const [parking, setParking] = useState();
  const [description, setDescription] = useState('');
  const [files, setUpload] = useState();
  
 
  const SubmitProperty=()=>{
      if (title == "") {
          errorToast('Title Field is empty')
        }
        else if (deal_type == "") {
          errorToast('Deal Type Field is empty')
        }
        else if (property_type == "") {
          errorToast('Property Type Field is empty')
        }
        else if (price == "") {
          errorToast('Price Field is Empty')
        }
        else if(address == ""){
          errorToast('Address Field is empty');
        }
        else if(bedrooms == ""){
          errorToast('Bedrooms Field is empty');
        }
        else if(bathrooms == ""){
          errorToast('Bathrooms Field is empty');
        }
        else if(kitchen == ""){
          errorToast('Kitchen Field is empty');
        }
        else if(area == ""){
          errorToast('Sq. Footage Field is empty');
        }
        else if(lot_size == ""){
          errorToast('Lot Size Field is empty');
        }
        else if(year_built == ""){
          errorToast('Year Built Field is empty');
        }
        else if(parking == ""){
          errorToast('Parking Field is empty');
        }
        else if(description == ""){
          errorToast('Description Field is empty');
        }
        else{
         
          //AddProperty(data)
          const form = document.getElementById("formImage");
          const docs = new FormData(form)
          console.log('uuuuuuuuuuuuuuuuuu',docs)
          let data = ({title:title,deal_type:deal_type,property_type:property_type,price:parseInt(price),address:address,
            bedrooms:parseInt(bedrooms), bathrooms:parseInt(bathrooms), kitchen:parseInt(kitchen),area:parseInt(area),lot_size:parseInt(lot_size),year_built:parseInt(year_built),parking:true,description:description,is_sold:false,images:[]
        })
        
        let all ={property_data:data} 
        console.log('hehhehe',data)
        
          
          axios({
            // headers: {
            //   'Content-Type': 'multipart/form-data'
            // },
            method: 'post',
            url: `http://localhost:8000/api/properties/new`,
            data: data,
            withCredentials:true,
          })
            .then((response) => {
              console.log('Hewe',response)
              if(response){
                successToast('Saved Successfully');
                return response.data;
              }
            })
        }
     
  }
  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getProperty();
      setData(data);
      console.log("goal",data)
    };
    fetchRequests();
  }, [])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  return (
    <>
    <Grid container spacing={2}>
    <Grid item xs={8}>
    <div className="flex flex-col items-right justify-start w-full gap-[15px]" style={{textAlign:'left'}}>
                    <Heading size="2xl" as="h1" className="tracking-[-0.92px]" style={{textAlign:'left', color:'black'}}>
                      <>
                      All Properties
                      </>
                    </Heading>
                    <Text  as="p" style={{textAlign:'left', color:'black'}}>
                    An overview of all your users, listed properties and payments
                    </Text>
                  </div>
    </Grid>
    <Grid item xs={4} className=" items-left ">
    <Button onClick={showModal}  style={{width:'170px',height:'43px', borderRadius:'10px', background:'#008080',color:'white'}} >+  Add Property</Button>
    </Grid>
   
  </Grid>
  <br/><br/>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {properties
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {/* {properties.map((column) => {
                      // const value = row[column.id];
                      return ( 
                        <>*/}
                        <TableCell >
                          <img src={row.Images[0] || NoImg} width={100} height={100} style={{borderRadius:'5px'}}/>
                        </TableCell>
                        <TableCell align="left">
                         {row.Title}
                        </TableCell>
                        <TableCell align="left">
                         {row.Address}
                        </TableCell>
                        <TableCell align="center">
                         {row.Price}
                        </TableCell>
                        <TableCell align="center">
                         {row.Type}
                        </TableCell>
                        <TableCell align="center">
                         {row.Bedrooms}
                        </TableCell>
                        <TableCell align="center">
                         {row.Bathrooms}
                        </TableCell>
                        <TableCell align="left">
                        
                        </TableCell>
                        {/* <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell> */}
                        {/* </>
                        );
                    })} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <Modal
title="Add Property"
open={open}
onOk={handleOk}
confirmLoading={confirmLoading}
onCancel={handleCancel}
footer={[
  <>
  <Button
    key="submit"
    type="primary"
    style={{ backgroundColor: '#ffff', color: '#008080',borderColor:"#008080"  }}
    loading={confirmLoading}
    onClick={handleOk}
  >
    Cancel
  </Button>
    <Button
    key="submit"
    type="primary"
    style={{ backgroundColor: '#008080', color: 'white',  }}
    loading={confirmLoading}
    onClick={SubmitProperty}
  >
    + add Property
  </Button></>
]}
>
  <br/>

<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
  <Form id='formImage'>
  <Col span={24}>
    <Typography style={{ color: '#000000', paddingTop:'10px' }}>Property Title</Typography>
    {/* <Input  style={{ color: '#000000' }} placeholder="Enter meeting Purpose " /> */}
    <TextField value={title}   onChange={text => setTitle(text.target.value)}  id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={24}>
    <Typography style={{ color: '#000000'}}>Property Type</Typography>
    <Select
          // size={'middle'}
          defaultValue="Select"
          // onChange={}
          style={{
            width: '100%',
             paddingTop:'10px',
             height:'50px' ,
          }}
          options={[{
            label: 'China',
            value: 'china',
          
          },
          {
            label: 'USA',
            value: 'usa',
          
          },
          {
            label: 'Japan',
            value: 'japan',
        
          },
          {
            label: 'Korea',
            value: 'korea',
          
          },]}
          onChange={text => setType(text)}
          value={property_type}
        />
  </Col>
  <Col span={24}>
    <Typography style={{ color: '#000000', paddingTop:'10px'  }}>Deal Type</Typography>
    <Select
          // size={'middle'}
          defaultValue="Select"
          options={[{
            label: 'China',
            value: 'china',
          
          },
          {
            label: 'USA',
            value: 'usa',
          
          },
          {
            label: 'Japan',
            value: 'japan',
        
          },
          {
            label: 'Korea',
            value: 'korea',
          
          },]}
          style={{
            width: '100%', paddingTop:'10px', height:'50px' , 
          }}
          // options={options}
          onChange={text => setDeal(text)}
          value={deal_type}
        />
  </Col>
  <Col span={24}>
    <Typography style={{ color: '#000000' }}>Price</Typography>
    <TextField value={price}    onChange={text => setPrice(text.target.value)} id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={24}>
    <Typography style={{ color: '#000000' }}>Address</Typography>
    <TextField  value={address}  onChange={text => setAddress(text.target.value)} id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={8} style={{ marginTop: 10 }}>
    <Typography>Bedroom</Typography>
    <Select
    value={bedrooms}
       onChange={text => setBedrooms(text)}
          // size={'middle'}
          defaultValue="Select"
          // onChange={}
          style={{
            width: '100%', paddingTop:'10px', height:'50px' , 
          }}
          options={[{
            label: '1',
            value: '1',
          
          },
          {
            label: '2',
            value: '2',
          
          },
          {
            label: '3',
            value: '3',
        
          },
          {
            label: '4',
            value: '4',
          
          },]}
        />
  </Col>
  <Col span={8} style={{ marginTop: 10 }}>
    <Typography>BathRoom</Typography>

    <Select
    value={bathrooms}
          // size={'middle'}
          onChange={text => setBathrooms(text)}
          defaultValue="Select"
          // onChange={}
          style={{
            width: '100%', paddingTop:'10px', height:'50px' , 
          }}
          options={[{
            label: '1',
            value: '1',
          
          },
          {
            label: '2',
            value: '2',
          
          },
          {
            label: '3',
            value: '3',
        
          },
          {
            label: '4',
            value: '4',
          
          },]}
        />
  </Col>

  <Col span={8} style={{ marginTop: 10 }}>
    <Typography>Kitchen</Typography>

    <Select
          // size={'middle'}
          value={kitchen}
          onChange={text => setKitchen(text)}
          defaultValue="Select"
          // onChange={}
          style={{
            width: '100%', paddingTop:'10px', height:'50px' , 
          }}
          options={[{
            label: '1',
            value: '1',
          
          },
          {
            label: '2',
            value: '2',
          
          },
          {
            label: '3',
            value: '3',
        
          },
          {
            label: '4',
            value: '4',
          
          },]}
        />
  </Col>
  <Col span={6} style={{ marginTop: 10 }}>
    <Typography>Sq. Footage</Typography>

    <TextField value={area}    onChange={text => setArea(text.target.value)} id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={6} style={{ marginTop: 10 }}>
    <Typography>Parking</Typography>

    <TextField value={parking}   onChange={text => setParking(text.target.value)}id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={6} style={{ marginTop: 10 }}>
    <Typography>Year Built</Typography>

    <TextField  value={year_built}   onChange={text => setBuilt(text.target.value)} id="outlined-basic" sx={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>
  <Col span={6} style={{ marginTop: 10 }}>
    <Typography>Lot Size</Typography>

    <TextField value={lot_size}    onChange={text => setSize(text.target.value)} id="outlined-basic" style={{ marginTop:'10px',  width:'100%'}}  variant="outlined" />

  </Col>

  <Col span={24} style={{ marginTop: 10 }}>
  <Typography>Description</Typography>
  <TextField
value={description}
onChange={text => setDescription(text.target.value)}
          id="outlined-multiline-static"
 
          multiline
          rows={4}
          defaultValue="Description"
          style={{ marginTop:'10px',  width:'100%'}} 
        />
  </Col>
  <Col span={24} style={{ marginTop: 10 }}>
  <Typography>Image</Typography>

  <Upload 
          // {...props}
          // fileList={ setUpload(fil)}
          name='images'
          >
           
            <div    className="avatar-uploader">
              <p className="ant-upload-drag-icon" style={{ marginLeft: '220px', fontSize: '50px', color: '#01A862' }}>
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text" style={{ marginLeft: '100px', textAlign: 'center' }}>Select a file or drag and drop here</p>
              <p className="ant-upload-hint" style={{ marginLeft: '100px', textAlign: 'center' }}>JPG, PNG or PDF, file size no more than 10MB</p>

              <br />
              <Button style={{ marginLeft: '200px', color: '#01A862', borderColor: '#01A862' }}>SELECT FILE</Button>
              <br />
            <p>Files Added</p>
            <br />
            </div>
        
          </Upload>
  </Col>
  </Form>
</Row>
</Modal>
    </>
  );
}