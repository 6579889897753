import React,{ useState, useContext,useEffect } from 'react';
import { Modal,message, Steps, theme,Input ,Flex } from 'antd';
import { Helmet } from "react-helmet";
import { Img, Heading, Button, TextArea, Radio, RadioGroup, Text, RatingBar, GoogleMap } from "../../components";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Header from "../../components/Header2";
import LandingPageCard from "../../components/LandingPageCard";
import { AppstoreFilled } from '@ant-design/icons';
import BgSky from "../../assets/images/Mask.png";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import LoadingPage from "../../components/Loader";

import Grid from "@mui/material/Grid";
import { CloudUploadOutlined } from '@ant-design/icons';
import TextField from "@mui/material/TextField";
import {  Upload } from 'antd';//Button,
import PropertySlide from "../../components/PropertySlide";
import Success from "./success";
import One from '../../assets/images/img_icon_gray_700.svg'
import Two from '../../assets/images/img_rectangle_5610.png'
import Img1 from '../../assets/images/img_rectangle_5612.png'
import Img2 from '../../assets/images/img_rectangle_5611.png'
import Img3 from '../../assets/images/img_icon_gray_700_20x20.svg'
import Img4 from '../../assets/images/img_icon_gray_700_20x20.svg'
import Img5 from '../../assets/images/left-arrow.png'
import Img6 from '../../assets/images/img_icon_24px_v.svg'
import Img7 from '../../assets/images/icons8-power-50.png'
import Img8 from '../../assets/images/icons8-share-64.png'
import Img9 from '../../assets/images/icons8-heart-50.png'
import Img10 from '../../assets/images/img_icon_gray_700_20x20.svg'
import Img11 from '../../assets/images/img_icon_gray_700.svg'
import Img12 from '../../assets/images/img_icon_gray_700_20x20.svg'
import {

  useParams
} from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';
import { errorToast,successToast } from "../../components/Toaster";
const steps = ['Offer Details', 'Proof of funds'];//, 'Acceptance'


const props = {
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      console.log(file, fileList);
    }
  },
  defaultFileList: [
    {
      uid: '1',
      name: 'xxx.png',
      status: 'uploading',
      url: 'http://www.baidu.com/xxx.png',
      percent: 33,
    },
    {
      uid: '2',
      name: 'yyy.png',
      status: 'done',
      url: 'http://www.baidu.com/yyy.png',
    },
    {
      uid: '3',
      name: 'zzz.png',
      status: 'error',
      response: 'Server Error 500',
      // custom error message to show
      url: 'http://www.baidu.com/zzz.png',
    },
  ],
}
export default function PropertyDetailsPage() {

  let { id } = useParams();
 
  const [payments, setPayment] = useState([]);
  const [imgx, setImage] = useState('');
  const [imgx2, setImage2] = useState('');
  const [imgx3, setImage3] = useState('');
  const [payments2, setPayment2] = useState([]);
  const { getProperty } = useContext(AuthContext);

  const [offer_amount, setOfferAm] = useState('');
  const [emd_amount, setEmdamount] = useState('');
  const [buyer_name, setBuyer] = useState('');
  const [buyer_company, setCompany] = useState('');
  const [proof_of_fund, setFund] = useState('');
  // const [buyer_company, setPassword] = useState('');
  // const [buyer_company, setPassword] = useState('');

  const SubmitOffer = () => {
    if (offer_amount == "") {
      errorToast('Offer Amount Field is empty')
    } else if (emd_amount == "") {
      errorToast('EMD Amount Field is empty')
    } else if (buyer_name == "") {
      errorToast('Buyer Name Field is empty')
    } else if (buyer_company == "") {
      errorToast('Buyer Company Field is empty')
    } else {
      let formData = new FormData();
      formData.append('offer_amount', offer_amount);
      formData.append('emd_amount', emd_amount);
      formData.append('buyer_name', buyer_name);
      formData.append('buyer_company', buyer_company);
      formData.append('property_id', String(id));
      formData.append('user_id', String(localStorage.getItem('user')));
  
      let files = []; // get your files here
      files.forEach(file => {
        formData.append('file', file);
      });
  
      SaveOffer(formData);
    }
  }
  
  const SaveOffer = async (formData) => {
    try {
      const response = await Request.post('offers/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response) {
        successToast('Sent Successfully');
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const SubmitOffer=()=>{
  //   if (offer_amount == "") {
  //       errorToast('Title Field is empty')
  //     }
  //     else if (emd_amount == "") {
  //       errorToast('Deal Type Field is empty')
  //     }
  //     else if (buyer_name == "") {
  //       errorToast('Property Type Field is empty')
  //     }
  //     else if (buyer_company == "") {
  //       errorToast('Property Type Field is empty')
  //     }
      
  //     else{
  //       let data = {offer_amount:offer_amount,emd_amount:emd_amount,buyer_name:buyer_name,buyer_company:buyer_company,property_id:String(id),user_id:String(localStorage.getItem('user')),file:[]}
  //       console.log('prop',{property_id:String(id),user_id:String(localStorage.getItem('user'))})
  //      // const data = { offer:offer, proof_of_fund:[], property_id:String(id),user_id:String(localStorage.getItem('user'))}
  //       SaveOffer(data)

  //     }
  //   }

  useEffect(() => {
    const fetchRequests = async () => {
      const data = await getProperty();
     
      if(data){
        setPayment2(data);
        const servePro = data.filter(i => i.id === id );
        setPayment(servePro[0]);
        let img = servePro[0]
        setImage(img.Images[0])
        setImage2(img.Images[1])
        setImage3(img.Images[2])
       
        
       }else{
        console.log('No data')
       }
    };
   
    fetchRequests();

  
  }, [])

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  
console.log('000000000000000',payments2)
const handleSteps = (step) => {
  switch (step) {
    case 0:
      return   <>
      <br/>
        <Grid container spacing={12} >
        
          <Grid item xs={12} sm={12}>
          <Typography sx={{ color:'black'}}>Make an Offer</Typography> 
          <br/>
            <TextField
              value={offer_amount}   
              onChange={text => setOfferAm(text.target.value)}
              fullWidth
              label="Offer Amount"
              name="Offer Amount"
              placeholder="Offer Amount"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
            value={emd_amount}   onChange={text => setEmdamount(text.target.value)}
              style={{marginTop:'-70px'}}
              fullWidth
              label="EMD Amount"
              name="EMD Amount"
              placeholder="EMD Amount"
             



            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
            value={buyer_name}   onChange={text => setBuyer(text.target.value)}
              style={{marginTop:'-100px'}}
              fullWidth
              label="Buyer Name"
              name="Buyer Name"
              placeholder="Buyer Name"
             



            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
            
              style={{marginTop:'-120px'}}
              fullWidth
              label="Buyer Company"
              name="Buyer Company"
              placeholder="Buyer Company"
             

              value={buyer_company}   onChange={text => setCompany(text.target.value)}

            />
          </Grid>

        
        </Grid>

      
      </>;
    case 1:
      return  <>
      <br/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Upload 
            // {...props}
            >
             
              <div    className="avatar-uploader">
                <p className="ant-upload-drag-icon" style={{ marginLeft: '220px', fontSize: '50px', color: '#01A862' }}>
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text" style={{ marginLeft: '100px', textAlign: 'center' }}>Select a file or drag and drop here</p>
                <p className="ant-upload-hint" style={{ marginLeft: '100px', textAlign: 'center' }}>JPG, PNG or PDF, file size no more than 10MB</p>
  
                <br />
                <Button style={{ marginLeft: '160px', color: '#01A862', borderColor: '#01A862',background:'#ffff' }}>SELECT FILE</Button>
                <br />
              <p>Files Added</p>
              <br />
              </div>
          
            </Upload>
          </Grid>
  
        </Grid>
        <br />
  
       
      </>;
    // case 2:
    //   return <Success />;
   
    default:
      // window.location.reload()
    
}
}
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (offer_amount == "") {
      errorToast('Title Field is empty')
    }
    else if (emd_amount == "") {
      errorToast('Deal Type Field is empty')
    }
    else if (buyer_name == "") {
      errorToast('Property Type Field is empty')
    }
    else if (buyer_company == "") {
      errorToast('Property Type Field is empty')
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const handleReset = () => {
    setActiveStep(0);
  };


  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };


  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const { token2 } = theme.useToken();
  const [current2, setCurrent2] = useState(0);
  const next2 = () => {
    setCurrent2(current2 + 1);
  };
  const prev2 = () => {
    setCurrent2(current2 - 1);
  };
 
  const contentStyle2 = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: "",
    borderRadius: token.Input,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };




  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };


  
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  
  return (
    <>
    <Helmet>
        <title>Property Details</title>
      </Helmet>
      <div className="flex flex-col items-center justify-start w-full gap-[100px] overflow-auto bg-white-A700">
        <div className="flex flex-col items-center justify-start w-full gap-[60px]">
          <div className="flex flex-col items-center justify-start w-full gap-10">
            <Header className="flex justify-center items-center w-full md:h-auto p-[19px] bg-white-A700" />
            
            <div className="flex flex-row justify-center w-full -mt-[26px] ">
            <div className="flex flex-row md:flex-col justify-start w-full gap-6 md:gap-5 md:px-5 max-w-[1200px]">
            
            <div className="flex flex-row justify-between w-full">
              <div  className="flex flex-row justify-start items-center gap-3">
              <a href="javascript:history.back()" style={{display:'inline-flex',padding:10}}>
              <Img src={Img5} alt="1_bath_one" className="h-[15px] w-[15px]" /> </a> 
              <a href="javascript:history.back()" style={{display:'inline-flex',padding:10}}>
                <Heading as="h2" className="!text-dark-700" style={{color:'dark'}}>
                Back to Search
                </Heading>
                </a> 
              </div>
            </div>

        
          
          </div>
          </div>

    {

payments.length == 0 ?
<>
<LoadingPage/>
</>
:

<div >

<div className="flex flex-row justify-center w-full">
  <div className="flex flex-row md:flex-col justify-start w-full gap-6 md:gap-5 md:px-5 max-w-[1200px]">
  
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
      {/* <Img src="images/img_icon_gray_700.svg" alt="3_bed_room_one" className="h-[20px] w-[20px]" /> */}
      <Heading as="h1"  style={{color:'black',fontWeight:"500px", fontSize:25}}>

      {payments.Title}

      </Heading>
   
    </div>
  </div>



</div>
</div>
<br/><br/>


<div className="flex flex-row justify-center w-full -mt-10">
  <div className="flex flex-row md:flex-col justify-start w-full gap-6 md:gap-5 md:px-5 max-w-[1200px]">
  
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
      {/* <Img src="images/img_icon_gray_700.svg" alt="3_bed_room_one" className="h-[20px] w-[20px]" /> */}
      <Heading as="h6" className="!text-gray-500" style={{color:'grey', fontSize:14}}>
      {payments.Address}
      </Heading>
   
    </div>
  </div>
</div>
</div>

<br/><br/>
<div className="flex flex-row justify-center w-full -mt-[26px] ">
  <div className="flex flex-row md:flex-col justify-start w-full gap-3 md:gap-5 md:px-5 max-w-[1200px]">
  
 

  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
     <img src={One} alt="1_bath_one" className="h-[15px] w-[15px]" /> <span> 5</span> &nbsp;
      <Img src={Img3} alt="1_bath_one" className="h-[15px] w-[15px]" /><span> 3</span>  &nbsp;
      <Img src={Img4} alt="1_bath_one" className="h-[15px] w-[15px]" /><span> 2</span>  &nbsp; |  &nbsp; Residential
    </div>
  </div>

</div>
</div>
<br/>
  <div className="flex flex-row justify-center w-full">
    
    <div className="flex flex-row md:flex-col justify-start w-full gap-6 md:gap-5 md:px-5 max-w-[1200px]">
     
      <div className="flex flex-row justify-start w-[66%] md:w-full">
        <Img
            src={imgx}
          alt="image"
          className="w-full md:h-auto sm:w-full object-cover rounded-[10px]"
        />
      </div>
      <div className="flex flex-col items-center justify-start w-[32%] md:w-full gap-6">
        <Img
          src={imgx3 || imgx}
          alt="image_one"
          className="w-full md:h-auto sm:w-full object-cover rounded-[10px]"
        />
        <div className="h-[263px] w-full relative">
          <Img
            src={imgx2 || imgx}
            alt="image_two"
            className="justify-center h-[263px] w-full sm:w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[10px]"
          />

<Heading as="h6" className="gap-1.5 bottom-[55%] right-[25%] m-auto text-gray-900 font-bold min-w-[122px] absolute" style={{color:'white', fontSize:20}}>
      + 18
      </Heading>
      <Heading as="h6" className="gap-1.5 bottom-[40%] right-[27%] m-auto text-gray-900 font-bold min-w-[122px] absolute" style={{color:'white', fontSize:20}}>
      Images
      </Heading>
          {/* <Button
            // color="white_A700"
            size="lg"
            shape="round"
            leftIcon={<Img src="images/img_icon_image.svg" alt="icon - image" />}
            className="gap-1.5 bottom-[6%] right-[4%] m-auto text-gray-900 font-bold min-w-[122px] absolute"
          >
            3 more
          </Button> */}
        </div>
      </div>
    </div>
  </div>
  <br/>
  <div className="flex flex-col items-center justify-start w-full">
    <div className="flex flex-row md:flex-col justify-start items-start w-full gap-6 md:gap-5 md:px-5 max-w-[1200px]">
      <div className="flex flex-col items-center justify-start w-[66%] md:w-full gap-6">
        <div className="flex flex-col items-center justify-start w-full gap-11 p-[39px] sm:p-5 border-blue_gray-100_01  bg-white-A700 rounded-[10px]">
          <div className="flex flex-col items-start justify-start w-full gap-[22px]">
            <div className="flex flex-col items-start justify-start w-full gap-[17px]">
            <Heading size="3xl" as="h3" className="tracking-[-0.56px]">
                Product Description
              </Heading>

              <Text as="p" style={{fontSize:14, color:'black'}}>

            {payments.Description}
            </Text>

           
         
            </div>
           
          </div>
          {/* <div className="flex flex-col items-start justify-start w-full gap-[17px]">
          <Heading size="3xl" as="h3" className="tracking-[-0.56px]">
                Tips
              </Heading>
            <Text as="p" style={{fontSize:14, color:'black'}}>
              A slider is great way to display a slideshow featuring images or videos, usually on your
              homepage.Adding sliders to your site is no longer difficult. You don’t have to know coding
              anymore. Just use a slider widget and it will automatically insert the slider on your web
              page.So, the Elementor slider would be a great tool to work with when building a WordPress site.
            </Text>
          </div> */}
       
        </div>
        
        
      </div>
      <div className="flex flex-col items-center justify-start w-[32%] sm:w-full gap-10 p-[23px] sm:p-5 border-blue_gray-100_01 border border-solid bg-white-A700 rounded-[10px]">
        <div className="flex flex-col items-start justify-start w-full pt-[3px] gap-[19px]">
          price
          <Heading size="3xl" as="h3" className="tracking-[-0.56px]" style={{color:'#008080',fontWeight:'bold'}}>
          {payments.Price}
          </Heading>
          {/* <div className="flex flex-col items-center justify-start w-full gap-3"> */}
        
            {/* <TextArea
              shape="round"
              name="inputbox_one"
              placeholder="Message"
              className="w-full sm:pb-5 sm:pr-5 text-gray-600_02 font-semibold"
            /> */}

  <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Sq. Footage
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments['Sq. Footage']}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Type
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments.Type}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Parking
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments.Parking}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3" >
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Year Built
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments['Year build']}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Lot Size
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments['Lot size']}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Bedrooms
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments.Bedrooms}
    </div>
  </div>

          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Bathrooms
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
    {payments.Bathrooms}
    </div>
  </div>

          </div>
          {/* <div className="flex flex-col items-center justify-start w-full gap-[19px]">
  <div className="flex flex-row justify-between w-full">
    <div className="flex flex-row justify-start items-center gap-3">
    <AppstoreFilled style={{color:'#008080'}} />
      <Heading as="h2" style={{fontWeight:'bold'}}>
      Kitchen
      </Heading>
    </div>
    <div className="flex flex-row justify-start items-center gap-3" style={{fontWeight:'bold'}}>
   
    </div>
  </div>

          </div> */}
        </div>
        <Button onClick={showModal} size="2xl" shape="round" style={{background:'#008080'}} className="w-full sm:px-5 font-semibold">
          Make Offer
        </Button>
      </div>
    </div>
  </div>
</div>
    }



          </div> 
         

          {

payments.length == 0 ?
<>

</>
:
<div className="flex flex-row justify-center w-full">
<div className="flex flex-col items-center justify-start w-full gap-[39px] md:px-5 max-w-[1200px]">
  <div className="flex flex-row sm:flex-col justify-between items-start w-full pt-[3px] sm:gap-10">
    <Heading size="3xl" as="h2" className="tracking-[-0.56px]">
    Discover Our Featured Listings
    </Heading>
    
    <div className="flex flex-row justify-start items-center mt-[3px] gap-2 sm:mt-0">
      <Heading size="md" as="h3" className="mt-0.5 !text-orange-A700">
        Explore All
      </Heading>
      <Img src={Img6} alt="iconarrow_one" className="h-[24px] w-[24px]" />
    </div>
  </div>
  <div className="flex flex-row md:flex-col w-full gap-6">
  <PropertySlide />
  </div>
</div>
</div>
}
        

          <div className="flex flex-col items-center justify-center w-full px-14 py-[70px] md:p-5 ">
          <div className="flex flex-col items-center justify-start w-full gap-[150px] max-w-[1200px]">
           
           
<div className="flex flex-col items-start justify-center w-[49%] md:h-auto gap-[49px] p-[50px] md:p-5  rounded-[20px]" style={{  background:  `url(${BgSky})` , backgroundSize: 'cover',
 backgroundRepeat: 'no-repeat', height: '70vh',
  width: '100%'
 }}>
              <div className="flex flex-col items-center justify-start mt-[23px] gap-[15px]">
                <Heading size="4xl" as="h6" className="tracking-[-0.72px]"style={{color:'black', marginRight:'90px'}}>
                Let us help you find <br/> your dream home
                </Heading>
                <Text as="p" className="!text-gray-900" style={{color:'#008080', marginRight:'10px'}}>
                We provide a complete service for the sale of homes <br/>and real estate
                </Text>
              </div>
              <Button shape="round" style={{backgroundColor:'#008080'}}className="mb-[23px] sm:px-5 font-semibold min-w-[138px] sm:min-w-full">
                CONTACT US
              </Button>
            </div>

   
          </div>
        </div>

        </div>
        <Footer className="flex justify-center items-center w-full pl-[74px] pr-14 gap-[115px] py-[74px] md:p-5 bg-white-A700" />
      </div>

      <Modal 
       open={isModalOpen} 
      //  onOk={handleOk} 
      onCancel={handleCancel}
       footer={[
        <>
        <div style={{display:'inline-flex', marginTop:'20px'}}>

        <Button  style={{height:'30px', width:'200px', borderRadius:'10px', fontSize:'10px', background:'white',color:'black', border:'25px',padding:'5px', borderColor:'grey'}} key="back" onClick={showModal3} >
        No, I don’t want financing
        </Button>,
        <Button style={{height:'30px', width:'200px', borderRadius:'10px', fontSize:'10px', background:'#008080',color:'white'}} key="submit" type="primary" onClick={()=>window.alert('Coming Soon!')}>
        Yes, I’d like financing offer
        </Button>
        </div>
       
        </>
      ]}
      styles={{width:'30%'}}
       >
        <Heading size="1xl" as="h6" className="tracking-[-0.72px]"style={{color:'black', textAlign:'center', fontSize:'18px', fontWeight:'bold'}}>
        Do you want Financing for this deal?
                </Heading>
        <p style={{ textAlign:'center', fontSize:'14px', marginTop:'20px' }}>Get financing offers in 24-48hours</p>
     
      </Modal>

      <Modal open={isModalOpen2} 
      // onOk={handleOk2} 
      onCancel={handleCancel2}
      footer={[]}
      >
        <br/>
        <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>
      </Modal>


      <Modal open={isModalOpen3} 
      // onOk={handleOk2} 
      onCancel={handleCancel3}
      footer={[]}
      >
       
        <br/> <br/>
     <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {/* {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {}; 

          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">Optional</Typography>
          //   );
          // }

          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
        //   return (
        //     <Step key={label} {...stepProps}>
        //       <StepLabel {...labelProps}>{label}</StepLabel>
        //     </Step>
        //   );
        // })}*/}
        {steps.map((label) => (
                  <Step key={label} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#01A862', // circle color (COMPLETED)
                    },
                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      color: 'grey.500', // Just text label (COMPLETED)
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#01A862', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: 'common.white', // Just text label (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'white', // circle's number (ACTIVE)
                    },
                  }}>
                    <StepLabel  >{label}</StepLabel>
                  </Step>
                ))}
      </Stepper>
      {handleSteps(activeStep)}
      {activeStep === steps.length ? (
        SubmitOffer(),
<>
<Success/>
</>

        // <React.Fragment>
          // {/* <Typography sx={{ mt: 2, mb: 1 }}>
          //   All steps completed - you&apos;re finished
          // </Typography>
          // <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          //   <Box sx={{ flex: '1 1 auto' }} />
          //   <Button onClick={handleReset}>Reset</Button>
          // </Box> */}
          
        // </React.Fragment> 
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            <Button onClick={handleNext} style={{backgroundColor:'#008080'}}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>


      </Modal>
    </>
  );
}
