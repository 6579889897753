import React from 'react'
import { Button, Img } from ".././components";
const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    

    const goToNextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
      <div className="flex flex-row justify-left gap-[5px]">
        {/* // <nav> */}
            {/* <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li> */}
               
                <Button
                 onClick={goToPrevPage} 
                  color="blue_gray_100_02"
                  variant="outline"
                  shape="round"
                  // rightIcon={<Img src="images/img_icon_16px_arrow_left.svg" alt="icon / 16px / arrow - right" />}
                  className="gap-1 font-semibold min-w-[134px]"
                >
                 {'<'} Previous 
                </Button>

                {/* {pageNumbers.map(pgNumber => (
                      <Button
                      color="blue_gray_100_02"
                      variant="outline"
                      shape="round"
                      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `}
                      key={pgNumber}
                      onClick={() => setCurrentPage(pgNumber)}
                    >
                      {pgNumber}
                    </Button>
                ))} */}

<Button
  onClick={goToNextPage}
                  color="blue_gray_100_02"
                  variant="outline"
                  shape="round"
                  // rightIcon={<Img src="images/img_icon_16px_arrow_right.svg" alt="icon / 16px / arrow - right" />}
                  className="gap-1 font-semibold min-w-[134px]"
                >
                  Next {'>'}
                </Button>
              
                {/* <li className="page-item">
                    <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                        
                        Next
                    </a>
                </li> */}
        {/* //     </ul> */}
        {/* // </nav> */}
        </div>
    )
}

export default Pagination



// import React from 'react';
// import { Button,  } from ".././components";
// const Pagination = ({ postsPerPage, length }) => {
//   const paginationNumbers = [];

//   for (let i = 1; i <= Math.ceil(length / postsPerPage); i++) {
//     paginationNumbers.push(i);
//   }

//   return (
    // <div className="flex flex-row justify-left gap-[5px]">
    //   {paginationNumbers.map((pageNumber) => (
    //     // <button key={pageNumber}></button>
    //     <Button
    //     color="blue_gray_100_02"
    //     variant="outline"
    //     shape="round"
    //     className="font-semibold min-w-[48px]"
    //     key={pageNumber}
    //   >
    //     {pageNumber}
    //   </Button>
    //   ))}
    // </div>
//   );
// };

// export default Pagination;